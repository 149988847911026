/* NotFoundPage.css */

.not-found-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .not-found-page h1 {
    font-size: 96px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .not-found-page p {
    font-size: 24px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .home-link {
    font-size: 18px;
    color: #007bff;
    text-decoration: none;
    border: 2px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .home-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  